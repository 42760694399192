module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LaurenCarlaCoaching","short_name":"LCC","start_url":"/","background_color":"#222222","theme_color":"#e6af2e","display":"standalone","icon":"src/images/lcc-icon-round.png","theme_color_in_head":false,"cache_busting_mode":"query","include_favicon":true,"legacy":true,"cacheDigest":"c23fdb4783cbe848ccabfdb8fe2c8092"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
