import { TOGGLE_THEME } from '../actions';

export const InitialViewState = {
  darkMode: false,
};

export const viewReducer = (state = { ...InitialViewState }, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return { ...state, darkMode: action.darkMode };
    default:
      return { ...state };
  }
};
