import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose,
} from 'redux';
import rootReducer from './reducers/';
import thunkMiddleware from 'redux-thunk';
import { InitialViewState } from './reducers/view.reducer';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension's options line name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

const initialAppState = {
  view: { ...InitialViewState },
};

const createStore = () =>
  reduxCreateStore(rootReducer, initialAppState, enhancer);
export default createStore;
